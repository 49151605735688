

































































































































































.visualization-goods-list-container {
  height: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 0.2rem;
  overflow: hidden;
  .goods-list-tag {
    display: flex;
    margin: 0.2rem 0 0.1rem 0.21rem;
    .goods-list-tag-title {
      flex: 1;
      .goods-list-tag-img {
        margin-right: 0.12rem;
      }
      .goods-list-name {
        font-size: 0.16rem;
        color: #fff;
      }
    }
    .goods-list-label {
      margin-right: 0.19rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      ::v-deep .goods-list-label-radio {
        .el-radio__label {
          color: #fff;
        }
        .el-radio__inner {
          background-color: transparent;
          border: 2px solid #4b45ff;
          border-radius: 50%;
          color: #ffffff;
        }

        .el-radio__input.is-radio + .el-radio__label {
          color: rgb(255, 255, 255);
        }

        .el-radio__input.is-radio .el-radio__inner {
          background-color: transparent;
          border: 2px solid #4b45ff;
          border-radius: 100%;
        }

        .el-radio__input.is-checked .el-radio__inner::after {
          background: #4b45ff;
        }
      }
    }
  }
  .visualization-goods-list-main {
    .goods-info {
      display: flex;
      align-items: center;
      .goods-img {
        border-radius: 7px;
      }
    }
    .font-settings {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      position: absolute;
      left: 30%;
      top: 15%;
    }
  }
}
